<template>
    <div>
        <add-gallery-image
            :is-add-gallery-image-sidebar-active.sync="
                isAddGalleryImageSidebarActive
            "
            :imageStatus="imageStatus"
            :datatag="tag"
            @clickedAddGalleryData="clickedAddGalleryData"
        />
        <add-edit-gallery
            :datatag="tag"
            :is-add-edit-gallery-sidebar-active.sync="
                isAddEditGallerySidebarActive
            "
            :editgalleryid="editgalleryid"
            :editgalleryName="editgalleryName"
            :editgalleryimg="editgalleryimg"
            :editgalleryTag="editgalleryTag"
            @clickeditimagegallerydata="clickeditimagegallerydata"
        />
        <b-overlay :show="show" rounded="sm">
            <b-card no-body class="padding_class">
                <b-modal
                    id="modal-xl"
                    hide-footer
                    size="lg"
                    centered
                    title="Images Resizing"
                >
                    <div class="modal_content">
                        <b-table-simple
                            hover
                            caption-top
                            responsive
                            class="rounded-bottom mb-0"
                        >
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th>Total Images</b-th>
                                    <b-th>Resize Images</b-th>
                                    <b-th>Remaining Resize Images</b-th>
                                    <b-th>Not Applicable Images</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <template>
                                    <b-tr>
                                        <b-td> {{ table_.length }}</b-td>
                                        <b-td>{{ resizeData.length }} </b-td>
                                        <b-td>{{ NotResizeData.length }}</b-td>
                                        <b-td>
                                            {{ NotAppicableIamge.length }}</b-td
                                        >
                                    </b-tr>
                                </template>
                            </b-tbody>
                        </b-table-simple>

                        <div class="modal-footer">
                            <div v-if="showspinner == true">
                                Please Wait..<b-spinner
                                    label="Spinning"
                                ></b-spinner>
                            </div>

                            <b-button
                                :disabled="showspinner"
                                @click="resizeAllImage"
                                style="margin-left: 7px"
                                variant="primary"
                                >proceed</b-button
                            >
                            <button
                                type="button"
                                @click="closejsonmodel()"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </b-modal>
                <div>
                    <b-row>
                        <b-col cols="12" md="12">
                            <b-modal
                                id="modal-tag-add"
                                hide-footer
                                size="sm"
                                centered
                                title="Add Tag"
                            >
                                <div class="modal_content">
                                    <b-form-group>
                                        <b-form-input
                                            v-model="addTag.name"
                                            placeholder="Add Tag"
                                            type="text"
                                        />
                                    </b-form-group>
                                    <div class="modal-footer">
                                        <b-button
                                            @click="addTagFunc"
                                            :disabled="submitbutton"
                                            style="margin-left: 7px"
                                            variant="primary"
                                            >Add
                                        </b-button>
                                    </div>
                                </div>
                            </b-modal>
                            <span v-for="(tag, index) in tag" :key="index">
                                <span v-if="tag.status == true">
                                    <b-button
                                        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                        variant="outline-info"
                                        style="background: #b3f7ff"
                                        @click="filterByTag(tag._id, index)"
                                    >
                                        {{ tag.name }}
                                    </b-button>
                                </span>
                                <span v-else>
                                    <b-button
                                        v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                        variant="outline-info"
                                        style="background: white"
                                        @click="filterByTag(tag._id, index)"
                                    >
                                        {{ tag.name }}
                                    </b-button>
                                </span>
                            </span>
                            <feather-icon
                                v-b-modal.modal-tag-add
                                style="
                                    margin: 2%;
                                    border: 1px solid black;
                                    border-radius: 4px;
                                "
                                icon="PlusIcon"
                                size="18"
                            />
                        </b-col>
                    </b-row>
                </div>

                <b-modal
                    id="edit-tag"
                    no-close-on-backdrop
                    hide-footer
                    size="sm"
                    centered
                    title="Edit Tag"
                >
                    <div class="modal_content">
                        <b-form-group label="Select Tag" label-for="Select Tag">
                            <v-select
                                multiple
                                :close-on-select="false"
                                v-model="selectedtag"
                                :options="tag"
                                label="name"
                                input-id="add-text"
                            >
                            </v-select>
                        </b-form-group>
                        <div class="modal-footer">
                            <b-button
                                @click="saveTag"
                                style="margin-left: 7px"
                                variant="primary"
                                >Save
                            </b-button>
                        </div>
                    </div>
                </b-modal>
                <b-modal
                    id="Add-tag"
                    hide-footer
                    size="sm"
                    centered
                    title="Add Tag"
                >
                    <div class="modal_content">
                        <b-form-group>
                            <b-form-input
                                v-model="rename.name"
                                placeholder="Rename"
                                type="text"
                            />
                        </b-form-group>
                        <div class="modal-footer">
                            <b-button
                                @click="renameSave"
                                style="margin-left: 7px"
                                variant="primary"
                                >Save
                            </b-button>
                        </div>
                    </div>
                </b-modal>
                <b-tabs>
                    <b-tab active @click="getimgdata(1)">
                        <template #title>
                            <span>All Images</span>
                        </template>
                        <div :key="sidebarkey">
                            <b-row class="match-height">
                                <b-col
                                    sm="3"
                                    md="2"
                                    lg="1"
                                    style="padding: 10px; position: relative"
                                    v-for="(galleryimg, index) in table_data"
                                    :key="galleryimg.img_id"
                                    v-lazy-container="{
                                        selector: 'img',
                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                        loading:
                                            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    }"
                                >
                                    <div
                                        class="cont-checkbox"
                                        v-if="galleryimg.showcheckbox == false"
                                    >
                                        <input
                                            type="checkbox"
                                            id="myCheckbox-1"
                                        />
                                        <b-tooltip
                                            :target="galleryimg._id + '1'"
                                            variant="primary"
                                        >
                                            <b-img
                                                :src="
                                                    imageTooltipText.gallery_img
                                                "
                                                fluid
                                                style="
                                                    width: 150px;
                                                    height: 150px;
                                                    object-fit: contain;
                                                "
                                                alt="Fluid image"
                                            ></b-img>

                                            <h6
                                                style="
                                                    color: white;
                                                    margin-top: 2px;
                                                "
                                            >
                                                {{
                                                    imageTooltipText.image_name
                                                }}
                                            </h6>
                                            <div
                                                class="
                                                    d-flex
                                                    align-items-center
                                                "
                                            >
                                                <div class="d-block w-100">
                                                    <feather-icon
                                                        style="
                                                            margin-right: 10px;
                                                        "
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="EditIcon"
                                                        @click="
                                                            editgalleryimage(
                                                                galleryimg._id,
                                                                galleryimg.image_name,
                                                                galleryimg.gallery_img,
                                                                galleryimg.tagId
                                                            )
                                                        "
                                                    />
                                                    <feather-icon
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="TrashIcon"
                                                        @click="
                                                            deletegalleryimg(
                                                                galleryimg._id
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        v-if="galleryimg.tagId"
                                                    >
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            fill="#99ebff"
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                    <span v-else>
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </b-tooltip>

                                        <label :for="galleryimg._id">
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <div v-if="galleryimg.resize">
                                                <img
                                                    :id="galleryimg._id + '1'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.resize[0].img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                            <div v-else>
                                                <img
                                                    :id="galleryimg._id + '1'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.gallery_img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="galleryimg.showcheckbox == true">
                                        <div
                                            class="cont-checkbox"
                                            v-if="
                                                galleryimg.opacityChange ==
                                                false
                                            "
                                        >
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            class="cont-checkbox checkedClass"
                                            v-else
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-stack flex-wrap">
                                        <div class="d-block w-100">
                                            {{ galleryimg.image_name }}
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab @click="recent_image_add_in_gallery(2)">
                        <template #title>
                            <span
                                ><img
                                    style="
                                        width: 30px;
                                        height: 30px;
                                        object-fit: contain;
                                    "
                                    src="https://zape02.s3.ap-southeast-1.amazonaws.com/0.313390532034791840.315927695955385830.01575768218445628.png"
                                    alt=""
                                />
                                Uploaded
                            </span>
                        </template>

                        <div :key="sidebarkey">
                            <b-row class="match-height">
                                <b-col
                                    sm="3"
                                    md="2"
                                    lg="1"
                                    style="padding: 10px; position: relative"
                                    v-for="(galleryimg, index) in table_data"
                                    :key="galleryimg.img_id"
                                    v-lazy-container="{
                                        selector: 'img',
                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                        loading:
                                            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    }"
                                >
                                    <div
                                        class="cont-checkbox"
                                        v-if="galleryimg.showcheckbox == false"
                                    >
                                        <input
                                            type="checkbox"
                                            id="myCheckbox-1"
                                        />
                                        <b-tooltip
                                            :target="galleryimg._id + '2'"
                                            variant="primary"
                                        >
                                            <b-img
                                                :src="
                                                    imageTooltipText.gallery_img
                                                "
                                                fluid
                                                style="
                                                    width: 150px;
                                                    height: 150px;
                                                    object-fit: contain;
                                                "
                                                alt="Fluid image"
                                            ></b-img>

                                            <h6
                                                style="
                                                    color: white;
                                                    margin-top: 2px;
                                                "
                                            >
                                                {{
                                                    imageTooltipText.image_name
                                                }}
                                            </h6>
                                            <div
                                                class="
                                                    d-flex
                                                    align-items-center
                                                "
                                            >
                                                <div class="d-block w-100">
                                                    <feather-icon
                                                        style="
                                                            margin-right: 10px;
                                                        "
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="EditIcon"
                                                        @click="
                                                            editgalleryimage(
                                                                galleryimg._id,
                                                                galleryimg.image_name,
                                                                galleryimg.gallery_img,
                                                                galleryimg.tagId
                                                            )
                                                        "
                                                    />
                                                    <feather-icon
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="TrashIcon"
                                                        @click="
                                                            deletegalleryimg(
                                                                galleryimg._id
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        v-if="galleryimg.tagId"
                                                    >
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            fill="#99ebff"
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                    <span v-else>
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </b-tooltip>

                                        <label :for="galleryimg._id">
                                            <div v-if="galleryimg.resize">
                                                <img
                                                    :id="galleryimg._id + '2'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.resize[0].img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                            <div v-else>
                                                <img
                                                    :id="galleryimg._id + '2'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.gallery_img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="galleryimg.showcheckbox == true">
                                        <div
                                            class="cont-checkbox"
                                            v-if="
                                                galleryimg.opacityChange ==
                                                false
                                            "
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            class="cont-checkbox checkedClass"
                                            v-else
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-stack flex-wrap">
                                        <div class="d-block w-100">
                                            {{ galleryimg.image_name }}
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab @click="recent_image_used_in_gallery(3)">
                        <template #title>
                            <span
                                ><img
                                    style="
                                        width: 30px;
                                        height: 30px;
                                        object-fit: contain;
                                    "
                                    src="https://zape02.s3.ap-southeast-1.amazonaws.com/0.313390532034791840.315927695955385830.01575768218445628.png"
                                    alt=""
                                />Used
                            </span>
                        </template>

                        <div :key="sidebarkey">
                            <b-row class="match-height">
                                <b-col
                                    sm="3"
                                    md="2"
                                    lg="1"
                                    style="padding: 10px; position: relative"
                                    v-for="(galleryimg, index) in table_data"
                                    :key="galleryimg.img_id"
                                    v-lazy-container="{
                                        selector: 'img',
                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                        loading:
                                            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    }"
                                >
                                    <div
                                        class="cont-checkbox"
                                        v-if="galleryimg.showcheckbox == false"
                                    >
                                        <input
                                            type="checkbox"
                                            id="myCheckbox-1"
                                        />
                                        <b-tooltip
                                            :target="galleryimg._id + '3'"
                                            variant="primary"
                                        >
                                            <b-img
                                                :src="
                                                    imageTooltipText.gallery_img
                                                "
                                                fluid
                                                style="
                                                    width: 150px;
                                                    height: 150px;
                                                    object-fit: contain;
                                                "
                                                alt="Fluid image"
                                            ></b-img>

                                            <h6
                                                style="
                                                    color: white;
                                                    margin-top: 2px;
                                                "
                                            >
                                                {{
                                                    imageTooltipText.image_name
                                                }}
                                            </h6>
                                            <div
                                                class="
                                                    d-flex
                                                    align-items-center
                                                "
                                            >
                                                <div class="d-block w-100">
                                                    <feather-icon
                                                        style="
                                                            margin-right: 10px;
                                                        "
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="EditIcon"
                                                        @click="
                                                            editgalleryimage(
                                                                galleryimg._id,
                                                                galleryimg.image_name,
                                                                galleryimg.gallery_img,
                                                                galleryimg.tagId
                                                            )
                                                        "
                                                    />
                                                    <feather-icon
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="TrashIcon"
                                                        @click="
                                                            deletegalleryimg(
                                                                galleryimg._id
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        v-if="galleryimg.tagId"
                                                    >
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            fill="#99ebff"
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                    <span v-else>
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </b-tooltip>

                                        <label :for="galleryimg._id">
                                            <div v-if="galleryimg.resize">
                                                <img
                                                    :id="galleryimg._id + '3'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.resize[0].img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                            <div v-else>
                                                <img
                                                    :id="galleryimg._id + '3'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.gallery_img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="galleryimg.showcheckbox == true">
                                        <div
                                            class="cont-checkbox"
                                            v-if="
                                                galleryimg.opacityChange ==
                                                false
                                            "
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            class="cont-checkbox checkedClass"
                                            v-else
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />

                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-stack flex-wrap">
                                        <div class="d-block w-100">
                                            {{ galleryimg.image_name }}
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab @click="getresizeImages(4)">
                        <template #title>
                            <span>Resized </span>
                        </template>
                        <div :key="sidebarkey">
                            <b-row class="match-height">
                                <b-col
                                    sm="3"
                                    md="2"
                                    lg="1"
                                    style="padding: 10px; position: relative"
                                    v-for="(galleryimg, index) in table_data"
                                    :key="galleryimg.img_id"
                                    v-lazy-container="{
                                        selector: 'img',
                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                        loading:
                                            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    }"
                                >
                                    <div
                                        class="cont-checkbox"
                                        v-if="galleryimg.showcheckbox == false"
                                    >
                                        <input
                                            type="checkbox"
                                            id="myCheckbox-1"
                                        />
                                        <b-tooltip
                                            :target="galleryimg._id + '4'"
                                            variant="primary"
                                        >
                                            <b-img
                                                :src="
                                                    imageTooltipText.gallery_img
                                                "
                                                fluid
                                                style="
                                                    width: 150px;
                                                    height: 150px;
                                                    object-fit: contain;
                                                "
                                                alt="Fluid image"
                                            ></b-img>

                                            <h6
                                                style="
                                                    color: white;
                                                    margin-top: 2px;
                                                "
                                            >
                                                {{
                                                    imageTooltipText.image_name
                                                }}
                                            </h6>
                                            <div
                                                class="
                                                    d-flex
                                                    align-items-center
                                                "
                                            >
                                                <div class="d-block w-100">
                                                    <feather-icon
                                                        style="
                                                            margin-right: 10px;
                                                        "
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="EditIcon"
                                                        @click="
                                                            editgalleryimage(
                                                                galleryimg._id,
                                                                galleryimg.image_name,
                                                                galleryimg.gallery_img,
                                                                galleryimg.tagId
                                                            )
                                                        "
                                                    />
                                                    <feather-icon
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="TrashIcon"
                                                        @click="
                                                            deletegalleryimg(
                                                                galleryimg._id
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        v-if="galleryimg.tagId"
                                                    >
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            fill="#99ebff"
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                    <span v-else>
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </b-tooltip>

                                        <label :for="galleryimg._id">
                                            <div v-if="galleryimg.resize">
                                                <img
                                                    :id="galleryimg._id + '4'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.resize[0].img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                            <div v-else>
                                                <img
                                                    :id="galleryimg._id + '4'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.gallery_img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="galleryimg.showcheckbox == true">
                                        <div
                                            class="cont-checkbox"
                                            v-if="
                                                galleryimg.opacityChange ==
                                                false
                                            "
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />

                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            class="cont-checkbox checkedClass"
                                            v-else
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />

                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-stack flex-wrap">
                                        <div class="d-block w-100">
                                            {{ galleryimg.image_name }}
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab @click="getNonresizeImages(5)">
                        <template #title>
                            <span>Pending Resize</span>
                        </template>
                        <div :key="sidebarkey">
                            <b-row class="match-height">
                                <b-col
                                    sm="3"
                                    md="2"
                                    lg="1"
                                    style="padding: 10px; position: relative"
                                    v-for="(galleryimg, index) in table_data"
                                    :key="galleryimg.img_id"
                                    v-lazy-container="{
                                        selector: 'img',
                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                        loading:
                                            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    }"
                                >
                                    <div
                                        class="cont-checkbox"
                                        v-if="galleryimg.showcheckbox == false"
                                    >
                                        <input
                                            type="checkbox"
                                            id="myCheckbox-1"
                                        />

                                        <b-tooltip
                                            :target="galleryimg._id + '5'"
                                            variant="primary"
                                        >
                                            <b-img
                                                :src="
                                                    imageTooltipText.gallery_img
                                                "
                                                fluid
                                                style="
                                                    width: 150px;
                                                    height: 150px;
                                                    object-fit: contain;
                                                "
                                                alt="Fluid image"
                                            ></b-img>

                                            <h6
                                                style="
                                                    color: white;
                                                    margin-top: 2px;
                                                "
                                            >
                                                {{
                                                    imageTooltipText.image_name
                                                }}
                                            </h6>
                                            <div
                                                class="
                                                    d-flex
                                                    align-items-center
                                                "
                                            >
                                                <div class="d-block w-100">
                                                    <feather-icon
                                                        style="
                                                            margin-right: 10px;
                                                        "
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="EditIcon"
                                                        @click="
                                                            editgalleryimage(
                                                                galleryimg._id,
                                                                galleryimg.image_name,
                                                                galleryimg.gallery_img,
                                                                galleryimg.tagId
                                                            )
                                                        "
                                                    />
                                                    <feather-icon
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="TrashIcon"
                                                        @click="
                                                            deletegalleryimg(
                                                                galleryimg._id
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        v-if="galleryimg.tagId"
                                                    >
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            fill="#99ebff"
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                    <span v-else>
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </b-tooltip>

                                        <label :for="galleryimg._id">
                                            <div v-if="galleryimg.resize">
                                                <img
                                                    :id="galleryimg._id + '5'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.resize[0].img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                            <div v-else>
                                                <img
                                                    :id="galleryimg._id + '5'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.gallery_img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="galleryimg.showcheckbox == true">
                                        <div
                                            class="cont-checkbox"
                                            v-if="
                                                galleryimg.opacityChange ==
                                                false
                                            "
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            class="cont-checkbox checkedClass"
                                            v-else
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-stack flex-wrap">
                                        <div class="d-block w-100">
                                            {{ galleryimg.image_name }}
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab @click="getNotapplicableImages(6)">
                        <template #title>
                            <span>N/A</span>
                        </template>
                        <div :key="sidebarkey">
                            <b-row class="match-height">
                                <b-col
                                    sm="3"
                                    md="2"
                                    lg="1"
                                    style="padding: 10px; position: relative"
                                    v-for="(galleryimg, index) in table_data"
                                    :key="galleryimg.img_id"
                                    v-lazy-container="{
                                        selector: 'img',
                                        error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                        loading:
                                            'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    }"
                                >
                                    <div
                                        class="cont-checkbox"
                                        v-if="galleryimg.showcheckbox == false"
                                    >
                                        <input
                                            type="checkbox"
                                            id="myCheckbox-1"
                                        />
                                        <b-tooltip
                                            :target="galleryimg._id + '6'"
                                            variant="primary"
                                        >
                                            <b-img
                                                :src="
                                                    imageTooltipText.gallery_img
                                                "
                                                fluid
                                                style="
                                                    width: 150px;
                                                    height: 150px;
                                                    object-fit: contain;
                                                "
                                                alt="Fluid image"
                                            ></b-img>

                                            <h6
                                                style="
                                                    color: white;
                                                    margin-top: 2px;
                                                "
                                            >
                                                {{
                                                    imageTooltipText.image_name
                                                }}
                                            </h6>
                                            <div
                                                class="
                                                    d-flex
                                                    align-items-center
                                                "
                                            >
                                                <div class="d-block w-100">
                                                    <feather-icon
                                                        style="
                                                            margin-right: 10px;
                                                        "
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="EditIcon"
                                                        @click="
                                                            editgalleryimage(
                                                                galleryimg._id,
                                                                galleryimg.image_name,
                                                                galleryimg.gallery_img,
                                                                galleryimg.tagId
                                                            )
                                                        "
                                                    />
                                                    <feather-icon
                                                        v-if="
                                                            removeButtons ==
                                                            true
                                                        "
                                                        icon="TrashIcon"
                                                        @click="
                                                            deletegalleryimg(
                                                                galleryimg._id
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        v-if="galleryimg.tagId"
                                                    >
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            fill="#99ebff"
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                    <span v-else>
                                                        <feather-icon
                                                            style="
                                                                margin-left: 10px;
                                                            "
                                                            v-b-modal.edit-tag
                                                            @click="
                                                                editTag(
                                                                    galleryimg.tagId,
                                                                    galleryimg._id
                                                                )
                                                            "
                                                            icon="TagIcon"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </b-tooltip>

                                        <label :for="galleryimg._id">
                                            <div v-if="galleryimg.resize">
                                                <img
                                                    :id="galleryimg._id + '6'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.resize[0].img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                            <div v-else>
                                                <img
                                                    :id="galleryimg._id + '6'"
                                                    @mouseover="
                                                        mouseOver(galleryimg)
                                                    "
                                                    :data-src="
                                                        galleryimg.gallery_img
                                                    "
                                                    alt=""
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="galleryimg.showcheckbox == true">
                                        <div
                                            class="cont-checkbox"
                                            v-if="
                                                galleryimg.opacityChange ==
                                                false
                                            "
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            class="cont-checkbox checkedClass"
                                            v-else
                                        >
                                            <input
                                                type="checkbox"
                                                id="myCheckbox-1"
                                            />
                                            <label :for="galleryimg._id">
                                                <div
                                                    v-if="galleryimg.resize"
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.resize[0]
                                                                .img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    @click="
                                                        checkboxdata(
                                                            galleryimg._id,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <img
                                                        :data-src="
                                                            galleryimg.gallery_img
                                                        "
                                                        alt=""
                                                    />
                                                </div>
                                                <span class="cover-checkbox">
                                                    <svg viewBox="0 0 12 10">
                                                        <polyline
                                                            points="1.5 6 4.5 9 10.5 1"
                                                        ></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-stack flex-wrap">
                                        <div class="d-block w-100">
                                            {{ galleryimg.image_name }}
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-overlay>
        <div
            style="
                position: fixed;
                background-color: white;
                bottom: 2%;
                display: flex;
                flex-direction: row;
                width: 90%;
            "
        >
            <div style="width: 60%">
                <b-button variant="primary" @click="preImage">Pre</b-button>
                <b-button
                    style="margin-left: 7px"
                    @click="nextImage()"
                    variant="primary"
                    >Next</b-button
                >
                <b-button
                    style="margin-left: 10px"
                    @click="selectImages"
                    variant="secondary"
                    v-if="removeButtons == true"
                    >Select</b-button
                ><b-button
                    @click="deletegalleryimg"
                    style="margin-left: 7px"
                    variant="danger"
                    :disabled="selectButton"
                    v-if="removeButtons == true"
                    >Delete</b-button
                >
                <b-button
                    v-b-modal.modal-xl
                    style="margin-left: 7px"
                    variant="secondary"
                    v-if="removeButtons == true"
                    >Resize</b-button
                >
                <b-button
                    v-b-modal.edit-tag
                    style="margin-left: 7px"
                    variant="secondary"
                    :disabled="selectButton"
                    @click="selectAllData"
                    v-if="removeButtons == true"
                    ><feather-icon
                        style="margin-right: 9px"
                        icon="EditIcon"
                    />Tag</b-button
                >
            </div>
            <div style="width: 40%; display: flex">
                <b-button
                    v-if="removeButtons == true"
                    variant="primary"
                    @click="isAddGalleryImageSidebarActive = true"
                    style="font-size: 12px"
                >
                    Upload
                </b-button>
                <b-form-input
                    style="width: 40%; margin-left: 4%"
                    v-model="searchQuery"
                    @input="getImages"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                />
            </div>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BImg,
    BCardTitle,
    BCardSubTitle,
    BTableSimple,
    BThead,
    BTbody,
    BDropdown,
    BDropdownItem,
    BTr,
    BTh,
    BTd,
    BLink,
    BFormGroup,
    BFormInput,
    BCarousel,
    BCarouselSlide,
    VBTooltip,
    BFormCheckbox,
    BBadge,
    BSpinner,
    BOverlay,
    BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import AddGalleryImage from "./app/list/users-list/AddGalleryImage.vue";
import AddEditGallery from "./app/list/users-list/AddEditGallery.vue";
import { BTabs, BTab } from "bootstrap-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        AddGalleryImage,
        ToastificationContent,
        BSpinner,
        BBadge,
        BOverlay,
        BTooltip,
        AddEditGallery,
        BCarousel,
        BCarouselSlide,
        BCard,
        BFormGroup,
        BCardText,
        BDropdown,
        BDropdownItem,
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTabs,
        BTab,
        BFormCheckbox,
        BButton,
        VBTooltip,
        BCardTitle,
        BCardSubTitle,
        BRow,
        BCol,
        BLink,
        BTbody,
        BImg,
        BFormInput,
        vSelect,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            imageStatus: "galleryImg",
            imageTooltipText: "",
            editgalleryid: "",
            resizeData: {},
            tag: [],
            addTag: {
                name: "",
            },
            NotResizeData: {},
            editgalleryimg: "",
            sidebarkey: 0,
            isAddGalleryImageSidebarActive: false,
            isAddEditGallerySidebarActive: false,
            perPage: 5,
            show: false,
            searchQuery: "",
            perPageOptions: [10, 25, 50, 100],
            table_data: [],
            table_: {},
            table_sort: {},
            pageNewData: 0,
            deletegallerydata: {
                img_id: "",
            },
            tabIndexKey: 1,
            MultiSelectedDeteted: [],
            selectedResizeImage: "",
            showspinner: false,
            NotAppicableIamge: {},
            searchResize: "",
            removeButtons: false,
            editgalleryName: "",
            allFolder: [],
            editgalleryTag: [],
            rename: {
                id: "",
                name: "",
            },
            selectedtag: [],
            tagImageId: "",
        };
    },
    computed: {
        selectButton() {
            return !(this.MultiSelectedDeteted.length > 0);
        },
        submitbutton() {
            return !(this.addTag.name.length > 0);
        },
    },
    mounted() {
        this.removeButtons = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "manage_gallery");
    },
    created() {
        this.getAllTag();
        this.getimgdata();
        this.Non_ResizeImage();
    },
    methods: {
        selectAllData() {
            this.selectedtag = [];
        },
        saveTag() {
            this.show = true;
            let tagArr = [];
            for (let index = 0; index < this.selectedtag.length; index++) {
                tagArr.push(this.selectedtag[index]._id);
            }
            let arrValue = [];

            arrValue = this.MultiSelectedDeteted;

            let editTagObject = {
                id: arrValue,
                tag: tagArr,
            };
            this.tagImageId = "";
            axios
                .post(
                    process.env.VUE_APP_API_URL + "/gallery-tag/edit",
                    editTagObject,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.message == "tag edit") {
                        this.alertMessageToast = "Tag edit successfully";

                        this.showToast("success");
                        for (let i = 0; i < this.table_data.length; i++) {
                            for (let j = 0; j < arrValue.length; j++) {
                                if (this.table_data[i]["showcheckbox"]) {
                                    this.table_data[i]["showcheckbox"] = false;
                                } else {
                                    this.table_data[i]["showcheckbox"] = false;
                                }
                                if (this.table_data[i]["_id"] == arrValue[j]) {
                                    this.table_data[i]["tagId"] = tagArr;
                                }
                            }
                        }

                        this.$root.$emit("bv::hide::modal", "edit-tag");
                        this.selectedtag = [];
                        this.MultiSelectedDeteted = [];
                        this.show = false;
                    } else {
                        this.alertMessageToast = "Tag not edit try again";
                        this.showToast("danger");
                        this.selectedtag = [];
                        this.MultiSelectedDeteted = [];
                        this.show = false;
                    }
                });
        },
        editTag(tagParam, imgId) {
            this.selectedtag = [];
            this.MultiSelectedDeteted = [];
            this.MultiSelectedDeteted.push(imgId);

            if (tagParam != undefined) {
                let arr = [];
                for (let j = 0; j < tagParam.length; j++) {
                    for (let i = 0; i < this.tag.length; i++) {
                        if (this.tag[i]._id == tagParam[j]) {
                            arr.push(this.tag[i]);
                        }
                    }
                }
                this.selectedtag = arr;
            }
        },
        filterByTag(id, index) {
            this.show = true;
            this.tag[index]["status"] = !this.tag[index]["status"];

            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                if (
                    this.tabIndexKey == 1 ||
                    this.tabIndexKey == 2 ||
                    this.tabIndexKey == 3
                ) {
                    let tagObject = {
                        id: idsArr,
                        tabId: 1,
                    };
                    axios
                        .post(
                            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                            tagObject,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + localStorage.getItem("_t"),
                                },
                            }
                        )
                        .then((response) => {
                            let dataArr = [];
                            for (let i = 0; i < response.data.length; i++) {
                                response.data[i]["showcheckbox"] = false;
                                dataArr.push(response.data[i]);
                            }
                            this.table_data = dataArr;
                            this.show = false;
                        });
                }
                if (this.tabIndexKey == 4) {
                    let tagObject = {
                        id: idsArr,
                        tabId: 2,
                    };
                    axios
                        .post(
                            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                            tagObject,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + localStorage.getItem("_t"),
                                },
                            }
                        )
                        .then((response) => {
                            this.table_data = response.data;
                            this.show = false;
                        });
                }
                if (this.tabIndexKey == 5) {
                    let tagObject = {
                        id: idsArr,
                        tabId: 3,
                    };
                    axios
                        .post(
                            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                            tagObject,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + localStorage.getItem("_t"),
                                },
                            }
                        )
                        .then((response) => {
                            let dataArr = [];
                            for (let i = 0; i < response.data.length; i++) {
                                response.data[i]["showcheckbox"] = false;
                                dataArr.push(response.data[i]);
                            }
                            this.table_data = dataArr;
                            this.show = false;
                        });
                }
                if (this.tabIndexKey == 6) {
                    let tagObject = {
                        id: idsArr,
                        tabId: 4,
                    };
                    axios
                        .post(
                            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                            tagObject,
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + localStorage.getItem("_t"),
                                },
                            }
                        )
                        .then((response) => {
                            let dataArr = [];
                            for (let i = 0; i < response.data.length; i++) {
                                response.data[i]["showcheckbox"] = false;
                                dataArr.push(response.data[i]);
                            }
                            this.table_data = dataArr;
                            this.show = false;
                        });
                }
            } else {
                if (this.tabIndexKey == 1) {
                    this.getimgdata(1);
                } else if (this.tabIndexKey == 2) {
                    this.recent_image_add_in_gallery();
                } else if (this.tabIndexKey == 3) {
                    this.recent_image_used_in_gallery(3);
                } else if (this.tabIndexKey == 4) {
                    this.getresizeImages(4);
                } else if (this.tabIndexKey == 5) {
                    this.getNonresizeImages(5);
                } else if (this.tabIndexKey == 6) {
                    this.getNotapplicableImages(6);
                } else {
                    this.getimgdata(1);
                }
            }
        },
        addTagFunc() {
            axios
                .post(
                    process.env.VUE_APP_API_URL + "/gallery-tag/add",
                    this.addTag,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.message == "Tag create successfully") {
                        this.alertMessageToast = response.data.message;
                        this.tag = response.data.data;

                        this.addTag.name = "";
                        this.showToast("success");
                        this.$root.$emit("bv::hide::modal", "modal-tag-add");
                    } else {
                        this.addTag.name = "";
                        this.alertMessageToast = "Tag not created try again";
                        this.showToast("danger");
                        this.$root.$emit("bv::hide::modal", "modal-tag-add");
                    }
                });
        },
        getAllTag() {
            axios
                .get(process.env.VUE_APP_API_URL + "/get-tags/all", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((res) => {
                    for (let index = 0; index < res.data.length; index++) {
                        res.data[index]["status"] = false;
                    }
                    this.tag = res.data;
                });
        },
        moveImages(id) {
            let moveImages = {
                folder_id: id,
                Images: this.MultiSelectedDeteted,
            };
            axios
                .post(
                    process.env.VUE_APP_API_URL +
                        "/gallery-images/move-to/folder",
                    moveImages,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.message == "Images moved successfully") {
                        this.alertMessageToast = response.data.message;
                        this.showToast("success");
                        this.$root.$emit("bv::hide::modal", "moveFolderModal");
                    } else {
                        this.alertMessageToast = "Fail to move images";
                        this.showToast("danger");
                        this.$root.$emit("bv::hide::modal", "moveFolderModal");
                    }
                });
        },
        renameSave() {
            axios
                .post(
                    process.env.VUE_APP_API_URL + "/gallery/rename-folder",
                    this.rename,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                )
                .then((response) => {
                    if (
                        response.data.message == "Folder renamed successfully"
                    ) {
                        this.alertMessageToast = response.data.message;

                        this.getAllFolder();
                        this.showToast("success");
                        this.$root.$emit("bv::hide::modal", "modal-rename");
                    } else {
                        this.alertMessageToast = "Folder not created try again";
                        this.showToast("danger");
                        this.$root.$emit("bv::hide::modal", "modal-rename");

                        //
                    }
                });
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        getAllFolder() {
            axios
                .get(process.env.VUE_APP_API_URL + "/getfolders/all", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((res) => {
                    this.allFolder = res.data;
                });
        },
        getNotapplicableImages(tabinde) {
            this.show = true;
            this.tabIndexKey = tabinde;
            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                let tagObject = {
                    id: idsArr,
                    tabId: 4,
                };
                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                        tagObject,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((response) => {
                        let dataArr = [];
                        for (let i = 0; i < response.data.length; i++) {
                            response.data[i]["showcheckbox"] = false;
                            dataArr.push(response.data[i]);
                        }
                        this.table_data = dataArr;
                        this.show = false;
                    });
            } else {
                this.searchQuery = "";
                this.table_data = [];
                this.pageNewData = 0;
                this.MultiSelectedDeteted = [];
                this.table_ = {};
                axios
                    .get(
                        process.env.VUE_APP_API_URL +
                            "/zape-cms/all/non-applicable/resize",
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((get_response) => {
                        this.table_data = [];
                        this.table_ = get_response.data.nonApplicable;
                        for (let index = 0; index < 50; index++) {
                            if (get_response.data.nonApplicable[index]) {
                                this.table_data.push(
                                    get_response.data.nonApplicable[index]
                                );
                                this.pageNewData = this.pageNewData + 1;
                            }
                        }
                        for (
                            let index = 0;
                            index < this.table_data.length;
                            index++
                        ) {
                            if (this.table_data[index]) {
                                this.table_data[index]["showcheckbox"] = false;
                            }
                        }
                        this.show = false;
                    });
            }
        },
        getNonresizeImages(tabinde) {
            this.show = true;
            this.tabIndexKey = tabinde;
            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                let tagObject = {
                    id: idsArr,
                    tabId: 3,
                };
                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                        tagObject,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((response) => {
                        let dataArr = [];
                        for (let i = 0; i < response.data.length; i++) {
                            response.data[i]["showcheckbox"] = false;
                            dataArr.push(response.data[i]);
                        }
                        this.table_data = dataArr;
                        this.show = false;
                    });
            } else {
                this.searchQuery = "";
                this.table_data = [];
                this.pageNewData = 0;
                this.MultiSelectedDeteted = [];
                this.table_ = {};
                axios
                    .get(
                        process.env.VUE_APP_API_URL +
                            "/zape-cms/all/non-resize",
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((get_response) => {
                        this.table_ = get_response.data.nonResize;
                        this.table_data = [];
                        for (let index = 0; index < 50; index++) {
                            if (get_response.data.nonResize[index]) {
                                this.table_data.push(
                                    get_response.data.nonResize[index]
                                );
                                this.pageNewData = this.pageNewData + 1;
                            }
                        }
                        for (
                            let index = 0;
                            index < this.table_data.length;
                            index++
                        ) {
                            if (this.table_data[index]) {
                                this.table_data[index]["showcheckbox"] = false;
                            }
                        }
                        this.show = false;
                    });
            }
        },
        getresizeImages(tabinde) {
            this.show = true;
            this.tabIndexKey = tabinde;
            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                let tagObject = {
                    id: idsArr,
                    tabId: 2,
                };
                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                        tagObject,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((response) => {
                        let dataArr = [];
                        for (let i = 0; i < response.data.length; i++) {
                            response.data[i]["showcheckbox"] = false;
                            dataArr.push(response.data[i]);
                        }
                        this.table_data = dataArr;
                        this.show = false;
                    });
            } else {
                this.searchQuery = "";
                this.table_data = [];
                this.pageNewData = 0;
                this.MultiSelectedDeteted = [];
                this.table_ = {};
                axios
                    .get(
                        process.env.VUE_APP_API_URL +
                            "/zape-cms/all/resize-images",
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((get_response) => {
                        this.table_data = [];
                        this.table_ = get_response.data.Resize;
                        for (let index = 0; index < 50; index++) {
                            if (get_response.data.Resize[index]) {
                                this.pageNewData = this.pageNewData + 1;
                                this.table_data.push(
                                    get_response.data.Resize[index]
                                );
                            }
                        }
                        for (
                            let index = 0;
                            index < this.table_data.length;
                            index++
                        ) {
                            if (this.table_data[index]) {
                                this.table_data[index]["showcheckbox"] = false;
                            }
                        }
                        this.show = false;
                    });
            }
        },
        closejsonmodel() {
            this.$root.$emit("bv::hide::modal", "modal-xl");
        },
        checkboxdata(i, index) {
            this.table_data[index]["opacityChange"] =
                !this.table_data[index]["opacityChange"];
            this.MultiSelectedDeteted = [];
            for (let j = 0; j < this.table_data.length; j++) {
                if (this.table_data[j]["opacityChange"] == true) {
                    this.MultiSelectedDeteted.push(this.table_data[j]["_id"]);
                }
            }
        },
        forcRender() {
            this.sidebarkey += 1;
        },
        selectImages() {
            for (let index = 0; index < this.table_data.length; index++) {
                if (this.table_data[index]["showcheckbox"]) {
                    this.table_data[index]["showcheckbox"] = false;
                } else {
                    this.table_data[index]["showcheckbox"] = true;
                }
                this.table_data[index]["opacityChange"] = false;
            }
            this.forcRender();
        },
        getImages() {
            this.show = true;
            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                setTimeout(() => {
                    if (this.searchQuery.trim().length > 0) {
                        let finaldata = this.table_data.filter((item) => {
                            if (item.image_name) {
                                return this.searchQuery
                                    .toLowerCase()
                                    .split(" ")
                                    .every((v) =>
                                        item.image_name
                                            .toLowerCase()
                                            .includes(v)
                                    );
                            }
                        });
                        for (let index = 0; index < finaldata.length; index++) {
                            if (finaldata[index]) {
                                finaldata[index]["showcheckbox"] = false;
                            }
                        }

                        this.table_data = finaldata;
                        this.show = false;
                    } else {
                        if (this.tabIndexKey == 1) {
                            this.getimgdata(1);
                        } else if (this.tabIndexKey == 2) {
                            this.recent_image_add_in_gallery();
                        } else if (this.tabIndexKey == 3) {
                            this.recent_image_used_in_gallery(3);
                        } else if (this.tabIndexKey == 4) {
                            this.getresizeImages(4);
                        } else if (this.tabIndexKey == 5) {
                            this.getNonresizeImages(5);
                        } else if (this.tabIndexKey == 6) {
                            this.getNotapplicableImages(6);
                        } else {
                            this.getimgdata(1);
                        }
                    }
                }, 2000);
            } else {
                setTimeout(() => {
                    if (this.searchQuery.trim().length > 0) {
                        let finaldata = this.table_.filter((item) => {
                            if (item.image_name) {
                                return this.searchQuery
                                    .toLowerCase()
                                    .split(" ")
                                    .every((v) =>
                                        item.image_name
                                            .toLowerCase()
                                            .includes(v)
                                    );
                            }
                        });
                        for (let index = 0; index < finaldata.length; index++) {
                            if (finaldata[index]) {
                                finaldata[index]["showcheckbox"] = false;
                            }
                        }
                        this.table_data = finaldata;
                        this.show = false;
                    } else {
                        if (this.tabIndexKey == 1) {
                            this.getimgdata(1);
                        } else if (this.tabIndexKey == 2) {
                            this.recent_image_add_in_gallery();
                        } else if (this.tabIndexKey == 3) {
                            this.recent_image_used_in_gallery(3);
                        } else if (this.tabIndexKey == 4) {
                            this.getresizeImages(4);
                        } else if (this.tabIndexKey == 5) {
                            this.getNonresizeImages(5);
                        } else if (this.tabIndexKey == 6) {
                            this.getNotapplicableImages(6);
                        } else {
                            this.getimgdata(1);
                        }
                    }
                }, 2000);
            }
        },
        deleteallimages() {
            axios
                .get(process.env.VUE_APP_API_URL + "/delete_Gallery_Image")
                .then((get_response) => {});
        },
        preImage() {
            if (this.pageNewData != 0) {
                this.table_data = [];
                for (let index = 50; index > 0; index--) {
                    this.pageNewData = this.pageNewData - 1;

                    if (this.table_[this.pageNewData]) {
                        this.table_[this.pageNewData]["showcheckbox"] = false;
                        this.table_data.push(this.table_[this.pageNewData]);
                    }
                }
            }
        },
        nextImage() {
            this.table_data = [];
            if (this.table_.length != 0) {
                for (let index = 0; index < 50; index++) {
                    this.pageNewData = this.pageNewData + 1;

                    if (this.table_[this.pageNewData]) {
                        this.table_[this.pageNewData]["showcheckbox"] = false;
                        this.table_data.push(this.table_[this.pageNewData]);
                    }
                }
            }
        },
        Non_ResizeImage() {
            axios
                .get(process.env.VUE_APP_API_URL + "/zape-cms/resize/count", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.NotResizeData = get_response.data.nonResize;
                    this.resizeData = get_response.data.Resize;
                    this.NotAppicableIamge = get_response.data.nonApplicable;
                });
        },
        ImageResize() {
            if (this.searchResize.trim().length > 0) {
                return this.resizeData.filter((item) => {
                    if (item.image_name) {
                        return this.searchQuery
                            .toLowerCase()
                            .split(" ")
                            .every((v) =>
                                item.image_name.toLowerCase().includes(v)
                            );
                    }
                });
            } else {
                return this.resizeData;
            }
        },
        async resizeAllImage() {
            this.showspinner = true;
            if (this.NotResizeData.length == 0) {
                this.showspinner = false;
                this.getimgdata();
                this.$root.$emit("bv::hide::modal", "modal-xl");
            } else {
                const chunkSize = 4;
                const totalImages = this.NotResizeData.length;
                const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

                const bearerToken = localStorage.getItem("_t");
                const processChunk = async (chunk) => {
                    let postObject = {
                        images : chunk
                    }
                    try {
                        await axios.post(
                            process.env.VUE_APP_API_URL + "/gallery/image/resize",
                            postObject,
                            {
                                headers: {
                                    Authorization: `Bearer ${bearerToken}`,
                                }
                            }
                        );
                    } catch (error) {
                        console.error("Error resizing images:", error);
                    }
                };
                const totalChunks = Math.ceil(totalImages / chunkSize);
                let processedChunks = 0;
                for (let i = 0; i < totalImages; i += chunkSize) {
                    const chunk = this.NotResizeData.slice(i, i + chunkSize);
                    await processChunk(chunk);
                    await sleep(1000); 

                    processedChunks++;

                    if (processedChunks % 5 === 0) {
                        await this.Non_ResizeImage();
                        processedChunks = 0;
                    }
                }

                if (totalChunks <= 5) {
                    await this.Non_ResizeImage();
                }

                this.showspinner = false;
                this.getimgdata();
                this.$root.$emit("bv::hide::modal", "modal-xl");
            }
        },
        recent_image_used_in_gallery: function (tabinde) {
            this.show = true;
            this.tabIndexKey = tabinde;
            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                let tagObject = {
                    id: idsArr,
                    tabId: 1,
                };
                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                        tagObject,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((response) => {
                        let dataArr = [];
                        for (let i = 0; i < response.data.length; i++) {
                            response.data[i]["showcheckbox"] = false;
                            dataArr.push(response.data[i]);
                        }
                        this.table_data = dataArr;
                        this.show = false;
                    });
            } else {
                this.searchQuery = "";
                this.table_data = [];
                this.pageNewData = 0;
                this.MultiSelectedDeteted = [];
                this.table_ = {};
                axios
                    .get(
                        process.env.VUE_APP_API_URL +
                            "/zape-cms/recent-used/images",
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((get_response) => {
                        this.table_data = [];
                        this.table_ = get_response.data;
                        for (let index = 0; index < 50; index++) {
                            if (get_response.data[index]) {
                                this.table_data.push(get_response.data[index]);
                                this.pageNewData = this.pageNewData + 1;
                            }
                        }
                        for (
                            let index = 0;
                            index < this.table_data.length;
                            index++
                        ) {
                            if (this.table_data[index]) {
                                this.table_data[index]["showcheckbox"] = false;
                            }
                        }
                        for (
                            let index = 0;
                            index < this.table_data.length;
                            index++
                        ) {
                            if (this.table_data[index]) {
                                this.table_data[index]["opacityChange"] = false;
                            }
                        }
                        this.show = false;
                    });
            }
        },
        recent_image_add_in_gallery: function (tabinde) {
            this.show = true;
            this.tabIndexKey = tabinde;
            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                let tagObject = {
                    id: idsArr,
                    tabId: 1,
                };
                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                        tagObject,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((response) => {
                        let dataArr = [];
                        for (let i = 0; i < response.data.length; i++) {
                            response.data[i]["showcheckbox"] = false;
                            dataArr.push(response.data[i]);
                        }
                        this.table_data = dataArr;
                        this.show = false;
                    });
            } else {
                this.searchQuery = "";
                this.table_data = [];
                this.pageNewData = 0;
                this.MultiSelectedDeteted = [];
                this.table_ = {};
                axios
                    .get(
                        process.env.VUE_APP_API_URL +
                            "/zape-cms/recent-add/images",
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((get_response) => {
                        this.table_data = [];
                        this.table_ = get_response.data;
                        for (let index = 0; index < 50; index++) {
                            if (get_response.data[index]) {
                                this.table_data.push(get_response.data[index]);
                                this.pageNewData = this.pageNewData + 1;
                            }
                        }
                        for (
                            let index = 0;
                            index < this.table_data.length;
                            index++
                        ) {
                            if (this.table_data[index]) {
                                this.table_data[index]["showcheckbox"] = false;
                            }
                        }
                        for (
                            let index = 0;
                            index < this.table_data.length;
                            index++
                        ) {
                            if (this.table_data[index]) {
                                this.table_data[index]["opacityChange"] = false;
                            }
                        }
                        this.show = false;
                    });
            }
        },
        getimgdata: function (tabinde) {
            this.show = true;

            this.tabIndexKey = 1;
            var idsArr = [];
            for (let i = 0; i < this.tag.length; i++) {
                if (this.tag[i]["status"] == true) {
                    idsArr.push(this.tag[i]["_id"]);
                }
            }
            if (idsArr.length > 0) {
                let tagObject = {
                    id: idsArr,
                    tabId: 1,
                };
                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/gallery-tag/filter",
                        tagObject,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + localStorage.getItem("_t"),
                            },
                        }
                    )
                    .then((response) => {
                        let dataArr = [];
                        for (let i = 0; i < response.data.length; i++) {
                            response.data[i]["showcheckbox"] = false;
                            dataArr.push(response.data[i]);
                        }
                        this.table_data = dataArr;
                        this.show = false;
                    });
            } else {
                (this.searchQuery = ""), (this.table_data = []);
                (this.pageNewData = 0),
                    (this.table_ = {}),
                    axios
                        .get(
                            process.env.VUE_APP_API_URL + "/zape-cms/gallery",
                            {
                                headers: {
                                    Authorization:
                                        "Bearer " + localStorage.getItem("_t"),
                                },
                            }
                        )
                        .then((get_response) => {
                            this.table_data = [];
                            this.table_ = get_response.data;
                            for (let index = 0; index < 50; index++) {
                                if (get_response.data[index]) {
                                    this.table_data.push(
                                        get_response.data[index]
                                    );
                                    this.pageNewData = this.pageNewData + 1;
                                }
                            }
                            for (
                                let index = 0;
                                index < this.table_data.length;
                                index++
                            ) {
                                if (this.table_data[index]) {
                                    this.table_data[index][
                                        "showcheckbox"
                                    ] = false;
                                }
                            }
                            for (
                                let index = 0;
                                index < this.table_data.length;
                                index++
                            ) {
                                if (this.table_data[index]) {
                                    this.table_data[index][
                                        "opacityChange"
                                    ] = false;
                                }
                            }
                            this.show = false;
                        });
            }
        },
        clickedAddGalleryData: function (gallery_data) {
            gallery_data["status"] = "gallery img";
            axios
                .post(
                    process.env.VUE_APP_API_URL + "/addgallerydata",
                    gallery_data
                )
                .then((response) => {
                    if (this.tabIndexKey == 1) {
                        this.getimgdata(1);
                    } else if (this.tabIndexKey == 2) {
                        this.recent_image_add_in_gallery();
                    } else if (this.tabIndexKey == 3) {
                        this.recent_image_used_in_gallery(3);
                    } else if (this.tabIndexKey == 4) {
                        this.getresizeImages(4);
                    } else if (this.tabIndexKey == 5) {
                        this.getNonresizeImages(5);
                    } else if (this.tabIndexKey == 6) {
                        this.getNotapplicableImages(6);
                    }
                });
        },
        clickeditimagegallerydata: function (edit_gallery_data) {
            axios
                .post(
                    process.env.VUE_APP_API_URL + "/edit_gallery_data",
                    edit_gallery_data
                )
                .then((response) => {
                    if (this.tabIndexKey == 1) {
                        this.getimgdata(1);
                    } else if (this.tabIndexKey == 2) {
                        this.recent_image_add_in_gallery(2);
                    } else if (this.tabIndexKey == 3) {
                        this.recent_image_used_in_gallery(3);
                    } else if (this.tabIndexKey == 4) {
                        this.getresizeImages(4);
                    } else if (this.tabIndexKey == 5) {
                        this.getNonresizeImages(5);
                    } else if (this.tabIndexKey == 6) {
                        this.getNotapplicableImages(6);
                    }
                });
        },
        deletegalleryimg: function (delete_gallery_data) {
            if (typeof delete_gallery_data == "string") {
                this.MultiSelectedDeteted = [];
                this.MultiSelectedDeteted.push(delete_gallery_data);
                this.deletegallerydata.img_id = this.MultiSelectedDeteted;
            } else {
                this.deletegallerydata.img_id = this.MultiSelectedDeteted;
            }

            axios
                .post(
                    process.env.VUE_APP_API_URL + "/delete_gallery_data",
                    this.deletegallerydata
                )
                .then((response) => {
                    if (response.data.message == "Image deleted successfully") {
                        this.alertMessageToast = response.data.message;

                        this.showToast("success");
                        this.MultiSelectedDeteted = [];
                        if (this.tabIndexKey == 1) {
                            this.getimgdata(1);
                        } else if (this.tabIndexKey == 2) {
                            this.recent_image_add_in_gallery();
                        } else if (this.tabIndexKey == 3) {
                            this.recent_image_used_in_gallery(3);
                        } else if (this.tabIndexKey == 4) {
                            this.getresizeImages(4);
                        } else if (this.tabIndexKey == 5) {
                            this.getNonresizeImages(5);
                        } else if (this.tabIndexKey == 6) {
                            this.getNotapplicableImages(6);
                        }
                    } else {
                        this.alertMessageToast =
                            "Images not deleted successfully";
                        this.showToast("danger");
                    }
                });
        },
        mouseOver(a) {
            this.imageTooltipText = a;
        },
        editgalleryimage(gallery_id, image_name, gallery_img, tagId) {
            this.isAddEditGallerySidebarActive = true;
            this.editgalleryid = gallery_id;
            if (image_name) {
                this.editgalleryName = image_name;
            }
            if (tagId != undefined) {
                this.editgalleryTag = tagId;
            } else {
                this.editgalleryTag = [];
            }
            this.editgalleryimg = gallery_img;
        },
    },
};
</script>
<style>
.zoom:hover {
    transform: scale(5) !important;
}
.box_container .box_inner .dropdown button {
    padding: 0 !important;
}
.box_container .box_inner {
    display: flex;
    justify-content: flex-start;
}
.box_container .dropdown-menu {
    min-width: 8rem;
    margin-top: -17px !important;
}
.box_container .dropdown a.dropdown-item {
    padding: 0px 8px;
}
.padding_class {
    padding: 1rem;
}

.cont-checkbox:first-of-type {
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
}

.cont-checkbox:active {
    transform: scale(0.9);
}

.cont-checkbox input {
    display: none;
}

.cont-checkbox input:checked + label {
    box-shadow: 0 0 0 3px var(--background);
}

.cont-checkbox.checkedClass img {
    opacity: 0.6;
    border: 5px solid black;
}

.cont-checkbox input:checked + label img {
    -webkit-filter: none;
    filter: none;
}

.cont-checkbox input:checked + label .cover-checkbox {
    opacity: 1;
    transform: scale(1);
}

.cont-checkbox input:checked + label .cover-checkbox svg {
    stroke-dashoffset: 0;
}

.cont-checkbox label {
    display: inline-block;
    cursor: pointer;
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;
    position: relative;
}

.cont-checkbox label img {
    width: 100%;
    height: 100%;
    width: 50px;
    height: 50px;
    border: 2px solid #faf9f6;

    border-radius: 10px;
}

.cont-checkbox label .cover-checkbox {
    position: absolute;
    right: 5px;
    top: 3px;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--box-shadow);
    border: 2px solid #fff;
    opacity: 0;
    transform: scale(0);
}

.cont-checkbox label .cover-checkbox svg {
    width: 13px;
    height: 11px;
    display: inline-block;
    vertical-align: top;
    fill: none;
    margin: 5px 0 0 3px;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
}

.cont-checkbox label .info {
    text-align: center;
    margin-top: 0.2rem;
    font-weight: 600;
    font-size: 0.8rem;
}

.cont-checkbox {
    transition: 1s !important;
}
.cont-checkbox .card {
    opacity: 0 !important;
    position: absolute;
    left: 50%;
    top: 0;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    transition: 1s !important;
    z-index: 1;
}
.cont-checkbox:hover .card {
    opacity: 1 !important;
    z-index: 9000;
    width: 150px;
    height: 150px;
    margin-left: -75px;
    transition: 0.3s !important;
}
.cardImg img {
    width: 150px;
    height: 150px;
}
</style>
